/* eslint-disable */
import React, { useContext, useState } from 'react';
import { ProductContext } from '../contexts/ProductContext';
import Product from '../components/Product';
import Hero from '../components/Hero';
import bestClothing from '../assetsObjects/bestClothing';
import skinCare from '../assetsObjects/bestSkinCare';
import allCategories from '../assetsObjects/allCategories';
import SwipeCategories from '../components/SwipeCategories';
import SwipeChildren from '../components/SwipeChildren';

const Home = () => {
  const { products } = useContext(ProductContext);
  //   console.log(products);
  const filteredProducts = products.filter((item) => (
    item.category === "men's_clothes" || item.category === "women's_clothes"
  ));
//   console.log(filteredProducts);

// console.log(allCategories)
  return (
    <div className=''>
      <Hero />

      <section>
        <div className='flex items-center justify-center'>
          <div className='w-[90%] p-5 bg-slate-100 mt-[-100px] rounded-2xl drop-shadow-2xl'>
            <h2 className='font-bold font-SteradianMedium text-lg'>Our Categories</h2>
            <SwipeCategories childrens={allCategories} />
          </div>
        </div>
      </section>

      <section>
        <div className='flex items-center justify-center'>
          <div className='w-[95%] p-5 mt-10 drop-shadow-2xl'>
            <h2 className='font-bold font-SteradianMedium text-lg'>Best Sellers in Clothing</h2>
            <SwipeChildren childrens={bestClothing} />
          </div>
        </div>
      </section>
      <section>
        <div className='flex items-center justify-center'>
          <div className='w-[95%] p-5 mt-10 drop-shadow-2xl'>
            <h2 className='font-bold font-SteradianMedium text-lg'>Best Sellers in Skin Care</h2>
            <SwipeChildren childrens={skinCare} />
          </div>
        </div>
      </section>
      {/* <section id="ourProducts" className="py-8 px-8">
        <div className="container mx-auto">
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-5 gap-[30px] max-w-sm mx-auto md:max-w-none md:mx-0">
                {products.map(product => {
                    return (
                        <Product product={product} key={product.id} />
                    );
                })}
            </div>
        </div>
      </section> */}
    </div>
  );
};

export default Home;
