/* eslint-disable */
import React, { createContext, useContext, useEffect, useState } from "react";
import { sidebarContext } from "../contexts/SidebarContext";
import { MenubarContext } from "../contexts/MenubarContext";
import { CartContext } from "../contexts/CartContext";
import logo from "../images/logo.png";

import { BsBag } from "react-icons/bs";
import { HiOutlineMenuAlt3 } from "react-icons/hi";
import SearchBar from "./SearchBar";
import { Link } from "react-router-dom";

const Header = () => {
  const [isActive, setIsActive] = useState(false);

  const { isOpen: sidebarIsOpen, setIsOpen: setSidebarIsOpen } =
    useContext(sidebarContext);
  const { isOpen: menubarIsOpen, setIsOpen: setMenubarIsOpen } =
    useContext(MenubarContext);

  const handleSidebarClick = () => {
    setSidebarIsOpen(!sidebarIsOpen);
  };

  const handleMenubarClick = () => {
    setMenubarIsOpen(!menubarIsOpen);
  };

  const { itemAmount } = useContext(CartContext);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      window.scrollY > 60 ? setIsActive(true) : setIsActive(false);
    });
  });

  return (
    <>
      <header
        className="p-2 bg-white lg:px-20"
        // className={`${
        //   isActive ? "bg-white shadow-md" : "bg-white"
        // } fixed w-full z-10 transition-all p-3 lg:px-20`}
      >
        <div className="container flex mx-auto items-center justify-between h-full">
          <a href="/">
            <div>
              <img className="w-[120px]" src={logo} alt="Logo" />
            </div>
          </a>

          {/* <Navbar /> */}
          {/* <SearchBar /> */}

          <div
            onClick={handleSidebarClick}
            className="cursor-pointer flex relative"
          >
            <BsBag className="text-2xl" />
            <div className="bg-red-500 absolute -right-2 -bottom-2 text-[12px] w-[18px] h-[18px] text-white rounded-full flex justify-center items-center">
              {itemAmount}
            </div>
          </div>
        </div>
        <div className="flex justify-center">
          <SearchBar />
        </div>
      </header>
      <nav className="bg-slate-100 flex flex-row gap-5 py-3 drop-shadow-md px-3">
        <div
          onClick={handleMenubarClick}
          // className="fixed mt-14 w-full z-10 transition-all lg:px-20 bg-slate-100"
          className="cursor-pointer flex items-center transition-all lg:px-20 text-black gap-1"
        >
          <HiOutlineMenuAlt3 />
          <p className="font-SteradianMedium">All</p>
        </div>
        <div className="flex justify-around w-full md:w-[30%]">
          <Link className="inline-block" to={`/scarves`}>Scarves</Link>
          <Link className="inline-block" to={`/skin_care`}>Skin Care</Link>
          <Link className="inline-block" to={`/women's_clothes`}>Women Clothes</Link>
        </div>
      </nav>
    </>
  );
};

export default Header;
