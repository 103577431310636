/* eslint-disable */
import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ProductContext } from '../contexts/ProductContext';
import Product from '../components/Product';

const SearchResults = () => {
  const location = useLocation();
  const { products } = useContext(ProductContext);
  const [filteredItems, setFilteredItems] = useState([]);
  
  useEffect(() => {
    const searchQuery = location.pathname.split('/').pop();
    if (products && Array.isArray(products) && searchQuery) {
      const filteredItems = products.filter(
        (item) =>
          item.title &&
          item.title.toLowerCase().includes(searchQuery.toLowerCase())
      );
      setFilteredItems(filteredItems);
    }
  }, [location.pathname, products]);

  return (
    <div>
      <section id="ourProducts" className="py-8 px-8">
        <div className="container mx-auto">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-5 gap-[30px] max-w-sm mx-auto md:max-w-none md:mx-0">
            {filteredItems.length > 0 ? (
              filteredItems.map(product => (
                <Product product={product} key={product.id} />
              ))
            ) : (
              <p>No items found</p>
            )}
          </div>
        </div>
      </section>
    </div>
  );
};

export default SearchResults;
