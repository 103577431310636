/* eslint-disable */
import React, { useContext, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { ProductContext } from '../contexts/ProductContext';
import Product from '../components/Product';

const ProductCategory = () => {
  const { products } = useContext(ProductContext);
  const {category, specific} = useParams()
  const [displayedProducts, setDisplayedProducts] = useState(null)

  useEffect(() => {
    if(!specific) {
      setDisplayedProducts(products.filter((item) => item.category === category));
    } else {
      setDisplayedProducts(products.filter((item) => item.specific === specific));
    }
  },[products, category, specific])

  return (
    <div>
      <section id="ourProducts" className="py-8 px-8">
        <div className="container mx-auto">
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-5 gap-[30px] max-w-sm mx-auto md:max-w-none md:mx-0">
                {displayedProducts && displayedProducts.map(product => {
                    return (
                        <Product product={product} key={product.id} />
                    );
                })}
            </div>
        </div>
      </section>
    </div>
  );
};

export default ProductCategory;
