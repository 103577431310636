/* eslint-disable */
import React, { useContext, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { FaSearch } from "react-icons/fa";

const SearchBar = () => {
  const [search, setSearch] = useState("");

  const handleSearchInputChange = (e) => {
    setSearch(e.target.value);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      window.location.href = `/search/${search}`;
    }
  };

  return (
    <div className="flex items-center justify-between mt-5 border border-gray-900 rounded-xl md:w-[50%]">
      <input
        type="search"
        // className="w-[80%] px-3 py-1 bg-transparent transition duration-250 ease-in-out text-base leading-6 text-gray-700 border-2 border-gray-700 rounded-full placeholder-gray-600 focus:outline-none focus:border-transparent focus:border-b-2 focus:border-gray-700 hover:bg-transparent"
        className="w-full h-9 px-3 py-1 bg-transparent transition duration-250 ease-in-out text-base  leading-6 text-gray-700 placeholder-gray-600
          focus:outline-none focus:border-transparent focus:border-b-2 focus:border-gray-700
          hover:bg-transparent"
        placeholder="Search"
        value={search}
        onChange={handleSearchInputChange}
        onKeyPress={handleKeyPress}
      />
      <Link
        to={`/search/${search}`}
        className="search-link bg-gray-900 rounded-r-xl"
      >
        <div className=" px-5  py-1 h-9 flex items-center justify-center ml-auto">
          <FaSearch className="text-xl text-white" />
        </div>
      </Link>
    </div>
  );
};

export default SearchBar;
