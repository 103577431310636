/* eslint-disable */
import React, { useContext, useEffect, useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import { CartContext } from "../contexts/CartContext";
import CartItem from "../components/CartItem";
import Lottie from "lottie-react";
import animationData from "../lotties/orderPlaced.json";
import { useNavigate } from "react-router-dom";

const Checkout = () => {
  const form = useRef();
  const navigate = useNavigate();

  const [thankYouPage, setThankYouPage] = useState(false);
  const [error, setError] = useState(null);
  const [shipping, setShipping] = useState(7);
  const [customer, setCustomer] = useState({
    name: "",
    phone: "",
    address: "",
  });

  const { cart, clearCart, total, itemAmount } = useContext(CartContext);

  useEffect(() => {
    // console.log('cart', cart.length)
    if (cart.length === 0 && !thankYouPage) {
      navigate("/");
    }
  }, []);

  useEffect(() => {
    const redirectTimeout = setTimeout(() => {
      if (thankYouPage) {
        window.location.href = "/";
      }
    }, 5000);

    return () => clearTimeout(redirectTimeout);
  }, [thankYouPage]);

  // useEffect(() => {
  //   console.log(thankYouPage);
  // }, [thankYouPage])

  // old template
  //   Hello {{to_name}},

  // You got a new Order for {{product}}:

  // name: {{name}}

  // phone: {{phone}}

  //   address: {{adresse}}

  // Best wishes,
  // EmailJS team

  // const templateParams = cart[0];
  // console.log(templateParams);

  const handleFormChange = (e) => {
    setCustomer({
      ...customer,
      [e.target.name]: e.target.value,
    });
    // console.log(customer);
  };

  const sendEmail = (e) => {
    e.preventDefault();

    const cartItems = [];

    for (let i = 0; i < cart.length; i++) {
      const productInfo = `Product:'${cart[i].title}' | Price:${cart[i].price} | Quantity:${cart[i].amount} | Product Link: https://peacelam.netlify.app/product/${cart[i].id}`;
      cartItems.push(productInfo);
    }

    emailjs
      .send(
        "service_p19m594",
        "template_jahmfeg",
        {
          products: cartItems,
          // products: [
          //   `Product:'${cart[0].title}' | Price:${cart[0].price} | Quantity :${cart[0].amount} | Product Link: https://peacelam.netlify.app/product/${cart[0].id} `,
          //   `Product:'${cart[1].title}' | Price:${cart[1].price} | Quantity :${cart[1].amount} | Product Link: https://peacelam.netlify.app/product/${cart[1].id} `,
          //   `Product:'${cart[2].title}' | Price:${cart[2].price} | Quantity :${cart[2].amount} | Product Link: https://peacelam.netlify.app/product/${cart[2].id} `,
          // ],
          total: `$ ${total}`,
          ...customer,
        },
        "awesLyUI-WjDq80SI"
      )
      .then(
        (result) => {
          // window.location.href = "/";
          setThankYouPage(true);
          // clearCart();
          // console.log(thankYouPage);
        },
        (error) => {
          setError("Something went wrong please repeat!");
          console.log(error.text);
        }
      );
  };

  if (cart.length === 0) {
    return;
  }

  return (
    <>
      {!thankYouPage && (
        <div className=" min-h-[70vh] py-5 px-5 md:px-32 bg-[#f8f7f5]">
          <div className="mb-5 w-full bg-white py-4 px-2 text-center font-SteradianMedium md:text-base rounded-xl text-[#273821] shadow-md">
            <p>
              Unlock free shipping on orders over $50! For orders under $50,
              enjoy a flat shipping fee of just $7
            </p>
          </div>
          <div className="flex flex-row lg:flex-col items-center">
            <div className="flex flex-col lg:flex-row gap-16 w-full pt-4 py-12">
              <div className="h-full w-full">
                <h1 className="mb-4 font-[900] font-SteradianMedium text-[#273821]">
                  We accept payments:
                </h1>
                <div className="text-center drop-shadow-xl bg-white p-10 h-full">
                  <p>_ Cash</p>
                  <p>_ PayPal</p>
                  <p>
                    _ zelle using this Gmail (peacelamshop2023@gmail.com)
                    temporarily
                  </p>
                  <p>
                    For any other questions please call us{" "}
                    <span className="font-SteradianMedium text-[#273821]">
                      2038682899
                    </span>
                    .
                  </p>
                </div>
                <h1 className="mt-10 mb-4 font-[900] font-SteradianMedium text-[#273821]">
                  Personal information
                </h1>
                <div className="drop-shadow-xl  bg-white p-5 h-full">
                  <form ref={form} onSubmit={sendEmail}>
                    {error && <h3 className="text-red-400">{error}</h3>}
                    <label className="block">Name*</label>
                    <input
                      onChange={handleFormChange}
                      className="block w-full p-1 border border-[#273821] focus:outline-[#486141] mb-3 text-[#273821] rounded-sm"
                      type="text"
                      name="name"
                      required
                    />
                    <label className="block">phone*</label>
                    <input
                      onChange={handleFormChange}
                      className="block w-full p-1 border border-[#273821] focus:outline-[#486141] mb-3 text-[#273821] rounded-sm"
                      type="tel"
                      name="phone"
                      required
                    />
                    <label className="block">address*</label>
                    <input
                      onChange={handleFormChange}
                      className="block w-full p-1 border border-[#273821] focus:outline-[#486141] mb-1 text-[#273821] rounded-sm"
                      type="text"
                      name="address"
                      required
                    />
                    <input
                      className="block mt-3 p-2 w-full bg-[#273821] text-white"
                      type="submit"
                      value="Confirm Order"
                    />
                  </form>
                </div>
              </div>
              <div className="w-full">
                <h1 className="mb-2 font-[900] font-SteradianMedium text-[#273821]">
                  Order Summary
                </h1>
                <div className="drop-shadow-xl bg-white pt-4 p-10 h-full">
                  <div className="lg:max-h-[630px] overflow-y-auto">
                    {cart.map((item) => {
                      return <CartItem item={item} key={item.id} />;
                    })}
                  </div>
                  <div className="mt-4">
                    <div className="flex justify-between">
                      <div>Subtotal</div>
                      <div>$ {total}</div>
                    </div>
                    <div className="flex justify-between">
                      <div>Shipping</div>
                      <div>$ {total < 50 ? shipping : 0}</div>
                    </div>
                    <div className="flex justify-between mt-2 text-[#273821] font-SteradianMedium">
                      <div>Total</div>
                      <div>$ {total + (total < 50 ? shipping : 0)}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {thankYouPage && (
        <div className="min-h-[60vh] flex items-center justify-center">
          <div className=" bg-[#f8f7f5] flex flex-col items-center justify-center w-[90%] md:w-[70%]  pt-3 p-4 md:p-10 rounded-xl my-4 drop-shadow-2xl">
            <div className="h-40 w-40">
              <Lottie
                animationData={animationData}
                height={100}
                width={200}
                loop={true}
                speed={2.5}
              />
            </div>
            <div className="w-full md:w-[70%] text-center p-5 mt-5 ">
              <h1 className="font-SteradianMedium text-xl mb-3">
                Thank you {customer.name} for ordering!
              </h1>
              <p>
                Your order has been successfully placed. Our team will be in
                touch with you shortly, If you have any immediate questions or
                concerns, feel free to reach out to us.
              </p>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Checkout;
