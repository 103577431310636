/* eslint-disable */
const categories = [
  {
    id: '01bvvTFIWxN3VcOBC9',
    name: 'Skin Care',
    url: "skin_care",
    imgPath: '../../assets/img/nilaCream.jpeg'
  },
  {
    id: '05HaAmt9lGPaLfMCUh',
    name: 'women\'s clothes',
    url: "women's_clothes",
    imgPath: '../../assets/img/jellaba5.jpeg'
  },
  {
    id: '080cVQcOKfYFH5VIMy',
    name: 'Men\'s clothes',
    url: "men's_clothes",
    imgPath: '../../assets/img/manDjellaba.jpeg'
  },
  {
    id: '0ACyp2v14pIeJgXBpT',
    name: 'Scarfs',
    url: "scarves",
    imgPath: '../../assets/img/scarf.jpeg'
  },
  {
    id: '0ACyp2v14pIekgXBpT',
    name: 'Hijab Bonnets',
    url: "scarves",
    imgPath: '../../assets/img/hijabBonnets.jpeg'
  },
]

export default categories