/* eslint-disable */
import React from 'react';

const Footer = () => {
    return (
        <footer className="bg-gray-900 py-12 mt-auto">
            <div className="container mx-auto flex justify-center">
                <p className="text-white">&copy;2023 all right reserved || peacelam.shop</p>
            </div>
        </footer>
    );
}

export default Footer;
