/* eslint-disable */
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import ProductDetails from './pages/ProductDetails';

import Sidebar from './components/Sidebar';
import Menubars from './components/Menubars';
import Header from './components/Header';
import Footer from './components/Footer';

import './App.css';
import ContactPage from './pages/Contact';
import ProductCategory from './pages/ProductCategory';
import SearchResults from './pages/SearchResults';
import Checkout from './pages/Checkout';

const App = () => (
  <div className="overflow-hidden font-SteradianRegular flex flex-col min-h-screen">
    <Router>
      <Header />
      <Menubars />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/:category" element={<ProductCategory />} />
        <Route path="/:category/:specific" element={<ProductCategory />} />
        {/* <Route path="/:search" element={<SearchResults />} /> */}
        <Route path="/search/:searchQuery" element={<SearchResults />} />
        <Route path="/product/:id" element={<ProductDetails />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/checkout" element={<Checkout />} />
      </Routes>
      <Sidebar />
      <Footer />
    </Router>
  </div>
);

export default App;
