/* eslint-disable */
import React, { createContext, useState } from 'react';
import sidebarData from '../Products/sidebar.json';


export const MenubarContext = createContext();
const MenubarProvider = ({ children }) => {

  const [item, setItem] = useState(sidebarData);
  const [displayedItem, setDisplayedItem] = useState(sidebarData);
  const [isOpen, setIsOpen] = useState(false);
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <MenubarContext.Provider value={{ item, displayedItem, isOpen, setIsOpen, open, setOpen, handleClose }}>
      {children}
    </MenubarContext.Provider>
  );
};

export default MenubarProvider;
