/* eslint-disable */
import React from "react";
import HeroImg from "../images/hero-img.png";

// bg-pink-200 h-[800px] bg-heero bg-no-repeat bg-cover bg-center py-24

const Hero = () => {
  return (
    // <section className="bg-hero h-[100vh] lg:h-[60vh] bg-no-repeat bg-cover bg-center py-24">
    <section className="bg-hero h-[50vh] lg:h-[30vh] lg:min-h-[600px] bg-no-repeat bg-cover bg-center">
      <div className="bg-slate-50/[.35] h-full">
        <div className="container mx-auto flex justify-around h-full">
          {/* <div className="flex flex-col md:justify-center mt-[-100px]"> */}
          <div className="flex flex-col justify-center mt-[-50px]">
            <div className="font-semibold flex items-center uppercase">
              <div className="w-10 h-[2px] bg-red-500 mr-3"></div>New products
              every month
            </div>
            <h1 className="pt-3 lg:pt-6 text-[30px] md:text-[70px] leading-[1.1] font-light lg:mb-4">
              Discover the beauty of <br />{" "}
              <span className="font-semibold text-[40px] md:text-[90px]">
                Morocco
              </span>{" "}
            </h1>
            <a
              href="#ourProducts"
              className="self-start uppercase font-semibold border-b-2 border-primary"
            >
              Discover Our Products
            </a>
          </div>

          {/* <div className="hidden lg:block">
              <img className="h-[700px]" src={HeroImg} alt="Hero Image" />
          </div> */}
        </div>
      </div>
    </section>
  );
};

export default Hero;
