/* eslint-disable */
import React, { useContext } from "react";
import { BsFillPlusCircleFill, BsEyeFill, BsPlus } from "react-icons/bs";
import { FaCartPlus } from "react-icons/fa";

import { Link } from "react-router-dom";
import { CartContext } from "../contexts/CartContext";

const Product = ({ product }) => {
  const { addToCart } = useContext(CartContext);
  const { id, image, category, title, price } = product;
  return (
    <div className="test">
      <div className="border border-[#e4e4e4] h-[300px] mb-4 relative overflow-hidden group transition">
        <div className="w-full h-full flex justify-center items-center">
          <img
            className="w-full group-hover:scale-110 transition duration-300"
            src={image}
            alt="Product Image"
            loading="lazy"
          />
        </div>
        <div className="absolute top-6 right-11 group-hover:right-0 p-2 flex flex-col items-center justify-center gap-y-2 opacity-0 group-hover:opacity-100 transition-all duration-300">
          <button onClick={() => addToCart(product, id)}>
            <div className="flex justify-center items-center text-primary w-12 h-12 bg-green-300">
              <FaCartPlus className="text-xl" />
            </div>
          </button>

          <Link to={`/product/${id}`}>
            <div className="w-12 h-12 bg-white flex justify-center items-center text-primary drop-shadow-xl">
              <BsEyeFill />
            </div>
          </Link>
        </div>
      </div>
      <div>
        <div className="text-sm capitalize text-gray-500 mb-1">{category}</div>
        <Link href={`/product/${id}`}>
          <h2 className="font-semibold mb-1">{title}</h2>
        </Link>
        <div className="font-semibold">$ {price}</div>
      </div>
    </div>
  );
};

export default Product;
