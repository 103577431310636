/* eslint-disable */
const bestClothing = [
  {
    id: 20,
    name: 'scarves',
    imgPath: '../../assets/img/scarf2.jpeg'
  },
  {
    id: 1,
    name: 'Jalaba for Women',
    imgPath: '../../assets/img/jellaba1.jpeg'
  },
  {
    id: 14,
    name: 'Ensemble for women',
    imgPath: '../../assets/img/dresses_1.jpeg'
  },
  {
    id: 21,
    name: 'Scarf',
    imgPath: '../../assets/img/scarf3.jpeg'
  },
  {
    id: 28,
    name: 'Long chemises',
    imgPath: '../../assets/img/longDresses_2.jpeg'
  },
  {
    id: 3,
    name: 'Jalaba for men',
    imgPath: '../../assets/img/manDjellaba.jpeg'
  },
  {
    id: 11,
    name: 'Jalaba for Women',
    imgPath: '../../assets/img/jellaba3.jpeg'
  },
]

export default bestClothing