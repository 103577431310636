/* eslint-disable */
// import React, { createContext, useEffect, useState } from 'react';

// export const ProductContext = createContext();

// const ProductProvider = ({ children }) => {
//   const [products, setProducts] = useState([]);

//   useEffect(() => {
//     const fetchProduct = async () => {
//       const response = await fetch('https://fakestoreapi.com/products');
//       const data = await response.json();
//       setProducts(data);
//       console.log(data);
//     };
//     fetchProduct();
//   }, []);

//   return <ProductContext.Provider value={{ products }}>{children}</ProductContext.Provider>;
// };

// export default ProductProvider;


/* eslint-disable */
import React, { createContext } from 'react';

import productData from '../Products/product.json'; // Import the local JSON file

// console.log('productData:', productData); // Log the content of product.json

export const ProductContext = createContext();

const ProductProvider = ({ children }) => {
  return <ProductContext.Provider value={{ products: productData }}>{children}</ProductContext.Provider>;
};

export default ProductProvider;
