/* eslint-disable */
import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import ProductProvider from "./contexts/ProductContext";
import SidebarProvider from "./contexts/SidebarContext";
import MenubarProvider from "./contexts/MenubarContext";
import CartProvider from "./contexts/CartContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <MenubarProvider>
      <SidebarProvider>
        <CartProvider>
          <ProductProvider>
            <React.StrictMode>
              <App />
            </React.StrictMode>
          </ProductProvider>
        </CartProvider>
      </SidebarProvider>
    </MenubarProvider>
);
