/* eslint-disable */
import React from "react";
import { FaPhoneAlt } from "react-icons/fa";
import { IoIosMail } from "react-icons/io";

const ContactPage = () => {
  return (
    <div className="min-h-screen flex justify-center items-center">
      {/* <p className="w-[45%]">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p> */}
      <div className="bg-white rounded-lg shadow-md w-[85%] p-10">
        <h1 className="text-3xl font-SteradianMedium mb-4">Contact Us</h1>
        <div className="mt-5">
          <FaPhoneAlt />
          <p>(203) 868-2899</p>
        </div>
        <div className="mt-5">
          <IoIosMail />
          <p>Peacelamshop2023@gmail.com</p>
        </div>
        {/* <form className="space-y-4">
          <div>
            <label className="block mb-1 font-semibold">Name</label>
            <input
              type="text"
              className="border border-gray-300 rounded-md p-2 w-full"
              placeholder="Your Name"
            />
          </div>
          <div>
            <label className="block mb-1 font-semibold">Email</label>
            <input
              type="email"
              className="border border-gray-300 rounded-md p-2 w-full"
              placeholder="Your Email"
            />
          </div>
          <div>
            <label className="block mb-1 font-semibold">Message</label>
            <textarea
              className="border border-gray-300 rounded-md p-2 w-full h-32 resize-none"
              placeholder="Your Message"
            ></textarea>
          </div>
          <button className="bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600" type="submit">
            Submit
          </button>
        </form> */}
      </div>
    </div>
  );
};

export default ContactPage;
