/* eslint-disable */
const skinCare = [
  {
    id: 2,
    name: 'Aragan soap',
    imgPath: '../../assets/img/araganSoap.jpeg'
  },
  {
    id: 8,
    name: 'Nila Powder',
    imgPath: '../../assets/img/NilaPowder.jpeg'
  },
  {
    id: 10,
    name: 'Akar Fassi Powder',
    imgPath: '../../assets/img/akarFassiPowder.jpeg'
  },
  {
    id: 7,
    name: 'Argan Lipstick',
    imgPath: '../../assets/img/arganLipstick.jpeg'
  },
  {
    id: 5,
    name: 'Aker Fassi pot',
    imgPath: '../../assets/img/akarFasiPot.jpeg'
  },
  {
    id: 27,
    name: 'Limited Promotion',
    imgPath: '../../assets/img/LipstickAkerFloral.jpeg'
  },
]

export default skinCare