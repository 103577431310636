/* eslint-disable */
import React, { useContext, useState } from 'react';
import { IoCloseCircleOutline } from "react-icons/io5";
import { MenubarContext } from '../contexts/MenubarContext';
import { IoIosArrowDown } from 'react-icons/io';
import logo from '../images/logo.png';

const Menubars = () => {
    // const navigate = useNavigate();
    const {isOpen, handleClose, displayedItem, open, setOpen} = useContext(MenubarContext);
    // const {displayedItem} = useContext(MenubarContext);
    const [openItems, setOpenItems] = useState({});

    const toggleOpen = (index) => {
      setOpenItems((prevOpenItems) => ({
        ...prevOpenItems,
        [index]: !prevOpenItems[index],
      }));
    };

    return (
        <div className={`${isOpen ? 'left-0' : '-left-full'} w-full bg-white fixed top-0 h-full shadow-2xl md:w-[35vw] xl:max-w-[30vw] transition-all duration-300 z-20 px-4 lg:px-[35px]`}>
            <div className="flex items-center justify-between py-6 border-b">
                
                <div>
                  <a href="/">
                    <div>
                      <img className="w-[100px]" src={logo} alt="Logo"/>
                    </div>
                  </a>
                </div>
                <div onClick={handleClose} className="cursor-pointer w-8 h-8 flex justify-center items-center" >
                    {/* <IoMdArrowForward className="text-2xl" /> */}
                    <IoCloseCircleOutline className="text-2xl"/>
                </div>
            </div>
            
            {displayedItem.map((menuItem, index) => (
            <div key={index} className={`${openItems[index] ? 'open' : ''}`}>
                <div className="flex items-center cursor-pointer text-base justify-between mt-3" onClick={() => toggleOpen(index)}>
                <a href={menuItem.path} className="flex items-center w-full">
                    {/* <GiClothes className="w-6 h-6 mr-2" /> */}
                    {menuItem.title}
                </a>
                {menuItem.childrens && (
                    <IoIosArrowDown className={`text-xl cursor-pointer transition-transform duration-300 ${openItems[index] ? 'rotate-180' : ''}`} />
                )}
                </div>
                {openItems[index] && menuItem.childrens && (
                <div>
                    {menuItem.childrens.map((childItem, childIndex) => (
                    <div key={childIndex} className={`${openItems[`${index}-${childIndex}`] ? 'open' : ''}`}>
                        <div className="flex items-center cursor-pointer text-base justify-between mt-2" onClick={() => toggleOpen(`${index}-${childIndex}`)}>
                        <span className="flex items-center ml-5">
                            {/* Second */}
                            <a className='block w-full' href={childItem.path}>
                              {childItem.title}
                            </a>
                            
                        </span>
                        {childItem.childrens && (
                            <IoIosArrowDown className={`text-xl cursor-pointer transition-transform duration-300 ${openItems[`${index}-${childIndex}`] ? 'rotate-180' : ''}`} />
                        )}
                        </div>
                        {openItems[`${index}-${childIndex}`] && childItem.childrens && (
                        <div>
                            {childItem.childrens.map((nestedChildItem, nestedChildIndex) => (
                            <div key={`${index}-${childIndex}-${nestedChildIndex}`} className="ml-10 mt-1 cursor-pointer">
                                {/* third */}
                                <a className='block w-full' href={nestedChildItem.path}>
                                  {nestedChildItem.title}
                                </a>
                            </div>
                            ))}
                        </div>
                        )}
                    </div>
                    ))}
                </div>
                )}
            </div>
            ))}
        </div>
    );
}

export default Menubars;
