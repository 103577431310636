/* eslint-disable */
import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Mousewheel, Keyboard, Autoplay } from 'swiper/modules';
import { Link } from 'react-router-dom';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

const SwipeChildren = ({ childrens }) => {
  return (
    <Swiper
      breakpoints={{
        640: {
          slidesPerView: 2,
          spaceBetween: 20,
        },
        768: {
          slidesPerView: 3,
          spaceBetween: 40,
        },
        1024: {
          slidesPerView: 5,
          spaceBetween: 50,
        },
      }}
      cssMode={true}
      loop={true}
      // autoplay={{
      //   delay: 2500,
      //   disableOnInteraction: false,
      // }}
      pagination={{
        enabled: true, clickable: true
      }}
      mousewheel={true}
      keyboard={true}
      modules={[Pagination, Mousewheel, Keyboard, Autoplay]}
      className="mt-3"
    >
      {childrens?.map((children) => (
        <SwiperSlide className="">
          <Link to={`product/${children.id}`} >
            <div className='flex flex-col items-center gap-3 bg-white p-5 rounded-lg'>
              <h2 className=''>{children.name}</h2>
              <div className='w-full h-[300px] overflow-hidden'>
                <img className='h-full w-full object-cover' src={children.imgPath} alt={children.name} />
              </div>
              <div className='uppercase font-SteradianMedium text-xs text-slate-500 hover:ml-2 duration-75'>Shop Now</div>
            </div>
          </Link>
        </SwiperSlide>
      ))}
    </Swiper>
  )
}

export default SwipeChildren